
import { defineComponent } from "vue";
import { isPlatform } from "@ionic/vue";
import Header from "@/components/Header.vue";
import { BuildInfo } from "@ionic-native/build-info";
import { VersionService } from "@/services/VersionService";
import Setting from "@/rest/Setting";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "About",
  components: {
    Header,
  },
  data() {
    let curVersion = BuildInfo.version ? BuildInfo.version.split("-")[0] : "--";
    return {
      curVersion: curVersion,
      versionService: new VersionService(),
    };
  },
  methods: {
    checkUpdate() {
      let isMobile = isPlatform("capacitor");
      if (isMobile) {
        this.versionService.checkAppVersion(true);
      } else {
        Utils.presentToastWarning("未启用版本更新");
      }
    },

    checkPrivacyPolicy() {
      this.$router.push({
        path: "/PrivacyPolicy",
      });
    },
  },
});
